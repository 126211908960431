import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, DEALER_NAME } from '../../constants/ActionTypes';
import axios from 'util/Api';

export const getDealerName = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .post('admin/user/dealer-list', {
                filter: {
                    addOwnUser: true,
                    isDeleted: false,
                    isActive: true
                }
            })
            .then((data) => {
                if (data.code === 'OK') {
                    let response = {};
                    response = data.data.list.reduce((val, cur) => (cur.id && { ...val, [cur.id]: cur.name }), {})
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: DEALER_NAME, payload: response });
                } else {
                    console.log('payload: data.error', data.error);
                    dispatch({ type: FETCH_ERROR, payload: [] });
                }
            })
            .catch((error) => {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log('Error****:', error.message);
            });
    };
};